<template>
  <AbstractFormPart :title="$t('question')">
    <CommentsField
      :is-optional="true"
      :placeholder="$t('placeholder')"
      v-on="$listeners"
    />
  </AbstractFormPart>
</template>

<script>
import CommentsField from 'chimera/all/components/form/fields/comments/CommentsField'

export default {
  name: 'CommentsFormPart',

  components: { CommentsField },
}
</script>

<i18n>
  {
    "nl-NL": {
      "question": "Zijn er nog bijzonderheden die je wilt vermelden?",
      "placeholder":"Alles wat je nog graag wil vermelden aan onze specialisten."
    },
    "nl-BE": {
      "question": "Zijn er nog bijzonderheden die u wilt vermelden?",
      "placeholder":"Alles wat u nog graag wil vermelden aan onze specialisten."
    },
    "es-ES": {
      "question": "¿Hay algún detalle que le gustaría mencionar?",
      "placeholder": "Cualquier otra cosa que le gustaría mencionar a nuestros especialistas."
    },
    "de-DE": {
      "question": "Gibt es Details, die Sie erwähnen möchten?",
      "placeholder":"Alles andere, was Sie unseren Spezialisten mitteilen möchten."
    },
    "fr-BE": {
      "question": "Voulez-vous mentionner autre chose?",
      "placeholder":"Tout ce que vous souhaitez mentionner à nos spécialistes."
    },
    "fr-FR": {
      "question": "Voulez-vous mentionner autre chose?",
      "placeholder":"Tout ce que vous souhaitez mentionner à nos spécialistes."
    }
  }
</i18n>
