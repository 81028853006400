<template>
  <div>
    <CommentsFormStep :progress-value="progressValue" />
  </div>
</template>

<script>
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import CommentsFormStep from '~/components/form/steps/comments/CommentsFormStep'

export default {
  name: 'CommentsFormPage',

  components: {
    CommentsFormStep,
  },

  extends: AbstractFormPage,

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Bijzonderheden',
      path: '/offertes-aanvragen/opmerkingen',
      progressValue: 60,
    }
  },
}
</script>
