<template>
  <AbstractFormPart :title="title">
    <WhatDeedsField
      :choices="choices"
      @result="onWhatDeedsResult"
      v-on="$listeners"
    />
    <ServiceField ref="service" hidden v-on="$listeners" />
  </AbstractFormPart>
</template>

<script>
import ServiceField from 'chimera/all/components/form/fields/service/ServiceField'
import WhatDeedsField from 'chimera/all/components/form/fields/whatDeeds/WhatDeedsField'

export default {
  name: 'WhatDeedsFormGroup',

  components: {
    WhatDeedsField,
    ServiceField,
  },

  props: {
    title: {
      type: String,
      required: true,
    },

    choices: {
      type: Array,
      required: true,
    },
  },

  methods: {
    /**
     * Finds the first service in the list of choices.
     *
     * @param {Array} value
     *
     * @returns {undefined|object}
     */
    findFirstSelectedService(value) {
      if (value.length === 0) {
        return
      }

      const selectionIds = value.map((val) => val.id)
      return this.choices.find((choice) => selectionIds.includes(choice.value))
    },

    /**
     * Set correct service based on selected deed
     *
     * @param {object} result
     * @param {boolean} result.isValid
     * @param {Array} result.value
     */
    onWhatDeedsResult({ isValid, value }) {
      if (!isValid) {
        return
      }

      const service = this.findFirstSelectedService(value)
      if (!service) {
        return
      }

      const serviceId = service.options.service.id
      this.$refs.service.setValue(serviceId)
    },
  },
}
</script>
